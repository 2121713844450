import React, { useEffect, useState } from 'react'
import { Link, useParams } from "react-router-dom";

import ExamplesNavbar from './Navbars/ExamplesNavbar';
import DarkFooter from './Footers/DarkFooter';
import {Helmet} from "react-helmet";
import image2 from '../views/pages/unnamed.png'


const Blog1 = () => {



  return (
    <>   
    <div id="layout" className="pure-g">
       <ExamplesNavbar />
      
      <div className="content pure-u-1 pure-u-md-3-4">
        <div>
          <div className="posts">
            

          <section className="post" style={{display:'grid'}}>
                <div className='singleblog'>
                <header className="post-header">
                  <h3 style={{color:'#07353d'}} className="post-title pt-3">
                  The MANiBOT Project   
                  </h3>
                  <div class="postcard__subtitle">
					<time >
						<i class="fas fa-calendar-alt mr-2"></i>
            10/11/2024
					</time>
				</div>
        <br/>
                 
                </header>
                <div className="post-description">
                <img style={{float:'left',margin:' 0px  20px 10px 0px'}} src={image2} title=""  height="400" />
                <h4>The goal and its importance  </h4>
                <p style={{textAlign:'justify'}}>
                In recent years, robotics has made significant progress in areas like perception, thinking, and movement. However, robots still demonstrate limited capabilities compared to that of humans especially when it comes to interacting with objects, humans and generally the real-world environment.
              </p> 
              <p style={{textAlign:'justify'}}>Industrial robots can handle objects quickly and precisely, but they are limited to controlled settings with pre-programmed tasks while they focus on handling a-priori known and modelled objects. Therefore, they are not equipped for the complexities of everyday environments, slowing down the adoption of robots in many potential areas of use.
              </p>
              <p style={{textAlign:'justify'}}>MANiBOT’s goal is to fill this gap and endow robots with human-like handling skills that will enable them to handle a wide variety of very diverse items efficiently, which do not have to be known in advance nor be modelled, while operating in real-world, human populated environments, in a safe manner. These capabilities will allow the use of robots in different sectors of industry and services from transport, logistics and retail to agri-food and manufacturing. MANiBOT’s use cases are indicative of the wide range of areas of potential use of the proposed technology, since they entail very diverse handling tasks, i.e. shelves’ restocking in supermarkets and baggage handling in airports.
              </p>
              <p style={{textAlign:'justify'}}>MANiBOT technologies are expected to have a significant scientific impact through its novel outputs. These outputs can lead to the creation of a new cobot market for manipulation tasks, revolutionising object-handling procedures. Moreover, they are expected to contribute to improving working conditions in environments with a high level of manual labour, improving workers’ safety and promoting robots’ user acceptance and adoption even in non-industrial environments.</p>
              <h4>MANiBOT Coordinator</h4>
              <p style={{textAlign:'justify'}}>CERTH (Center for Research and Technology Hellas) is one of the most important Research Centers in Greece. Its main mission is: the promotion of innovative research for the benefit of society. Dedicated to this purpose, CERTH stands at the forefront of basic, applied and technological research, providing solutions to modern societal challenges while participating in and coordinating numerous EU projects.</p>
              <p style={{textAlign:'justify'}}>Totally aligned with this mission, and based on its experience in leading roles in EU projects, CERTH is the coordinator of the MANiBOT project. Gathering a group of high-profile partners carefully selected for their acknowledged excellence on their fields, CERTH aims to promote scientific knowledge and provide innovative robotic technologies with a high impact on science, society and the economy.</p>
              <h4>MANiBOT’s progress</h4>
              <p style={{textAlign:'justify'}}>One year in, MANiBOT has already made important progress regarding the identification of users’ needs for such a robot, the robot’s design and its technical requirements, as well as the design and development of novel sensors. Moreover, there was progress on the development of innovative methods that will enable the robot to understand its environment, decide on the movements it has to make to achieve its handling goals and the way it will perform them. The consortium is also addressing the legal, ethical and socio-economic issues related to the development and application of MANiBOT technologies. </p>
              <h4>Closing Thoughts</h4>
              <p style={{textAlign:'justify'}}>The world of robotics is constantly evolving, and projects like MANiBOT are at the forefront of pushing boundaries in object manipulation. If you are intrigued by the potential of more capable and adaptable robots, we invite you to follow our journey. Stay up-to-date with our latest developments and see our innovations unfold in real-time by following us on social media. You can find us on 
                <a href='https://www.linkedin.com/showcase/manibot/'>LinkedIn</a>, <a href='https://x.com/MANiBOT_project'>X </a>(formerly Twitter), and 
                <a href='https://www.youtube.com/@MANiBOTProject'> YouTube</a>, and sign up for our newsletter <a href='https://manibot-project.us22.list-manage.com/subscribe?u=3fc0b5ad1e28dfc0eee470610&id=5654c5fdc6'>here</a>.</p>
              
              
              </div>
                
                
                </div>
               
                
              </section>
               
                    
          </div>


          
        </div>
      </div>
      <DarkFooter/>
    </div>
    </>
  )
}

export default Blog1