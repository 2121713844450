import React, {useState} from "react";
import "../../assets/scss/styles.scss"

import DarkFooter from "../../components/Footers/DarkFooter.js";







import {
  Button,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import ExamplesNavbar from "../../components/Navbars/ExamplesNavbar.js";
import LandingPageHeader from "../../components/Headers/LandingPageHeader.js";
import Modal from 'react-bootstrap/Modal';


function FAQ() {
  
 
  return (
    <>
      <ExamplesNavbar />
      <div className="wrapper">
        
 
      <section className="section section-about-us" id='FAQ' >
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8" >
                <h2 className="hr" style={{color:'#2f5597',marginTop:'50px',textAlign:'justify'}}>MANiBOT Frequently Asked Questions</h2>
                <p style={{textAlign:'justify',fontStyle:'italic'}}>This page aims to answer key questions that visitors to the site may have about MANiBOT. It covers topics such as ethics, safety and AI, what the project aims to achieve and its planned impact.</p>
                <h5  style={{textAlign:'justify',color:'#2f5597',fontWeight:'600'}}>
                What are the main challenges MANIBOT aims to address?
                </h5>
                <p style={{textAlign:'justify'}}>Despite advances in robotic perception, understanding and control, collaborative service robots still demonstrate limited physical performance compared to that of humans.</p>
                <p style={{textAlign:'justify'}}>This is particularly the case when it comes safe and efficient robot-environment interaction with diverse object manipulation in real, human-populated spaces. Because of this, the uptake of such robots in key sectors is still limited compared to their vast potential uses.</p>
                <p style={{textAlign:'justify'}}>Industrial level robots handle objects well, and quickly, to the level of humans or beyond human capabilities, but only in the context of handling well-known objects that have been pre-programmed, and within controlled environments.
                </p>
                <p style={{textAlign:'justify'}}>Empowering service robots with advanced physical capabilities and efficiency needs major advances in many cross-cutting research topics. The advances are needed so that these robots can carry out a wide variety of manipulation tasks, in real-world environments, with two robotic “hands”, in a human-like manner and with human-like performance.</p>
                <h5  style={{textAlign:'justify',color:'#2f5597',fontWeight:'600'}}>
                What is a bi-manual mobile service robot? 
                </h5>
                <p style={{textAlign:'justify'}}>This is a type of robotic system that features two arms (bi-manual) and is capable of moving around its environment (mobile) to perform various service-oriented tasks.</p>

                <h5  style={{textAlign:'justify',color:'#2f5597',fontWeight:'600'}}>
                What is a cobot?
                </h5>
                <p style={{textAlign:'justify'}}>Cobots are designed to function alongside or directly with humans. While most other types of robots perform their tasks independently, or in strictly isolated work areas, cobots can share spaces with workers to help them accomplish more. They’re often used to eliminate manual, dangerous, or strenuous tasks from day-to-day workflows. In some cases, cobots can operate by responding to and learning from human movements. <br/><a href='https://www.intel.com/content/www/us/en/robotics/types-and-applications.html'>https://www.intel.com/content/www/us/en/robotics/types-and-applications.html </a></p>

                <h5  style={{textAlign:'justify',color:'#2f5597',fontWeight:'600'}}>
                What does the MANiBOT project intend to achieve? What new or advanced capabilities will the MANiBOT cobots have? 
                </h5>
                <p style={{textAlign:'justify'}}>The mobile robots will have advanced capabilities that will enable them to carry out complex movements of objects to achieve complex goals and to quickly handle diverse items in a human-like manner. </p>
                <p style={{textAlign:'justify'}}>These capabilities include:</p>
                <ul style={{textAlign:'justify',fontSize:'1rem'}}>
                  <li>New ways for robots to understand their surroundings and recognise objects, using sight, touch, and proximity sensors.
                  </li>
                  <li>A new set of programmed movements for robots to handle objects, including grasping and moving them without grabbing them. </li>
                  <li>Smart robot elements that combine advanced touch and proximity sensors with the bi-manual robot arm. </li>
                  <li>A new approach for robots to think, using different levels of tasks that help them learn, put actions together, and quickly change how they behave.</li>
                </ul>
                <p style={{textAlign:'justify'}}>The MANiBOT solution will optimise energy efficiency and autonomy and ensure trustworthy human-robot interaction in challenging spaces. </p>
                <p style={{textAlign:'justify'}}>MANiBOT’s mobile robots will have improved abilities to adapt to changes in their environment and to operate safely in human-populated spaces </p>

                <h5  style={{textAlign:'justify',color:'#2f5597',fontWeight:'600'}}>
                What impact is MANiBOT expected to have? How will MANiBOT impact the job market? How will MANiBOT improve customer experience in the targeted sectors?
                </h5>
                <ul style={{textAlign:'justify',fontSize:'1rem'}}>
                  <li>MANiBOT will reduce human involvement in physical tasks requiring repetitive movements and heavy lifting, improving workers’ health and job quality.</li>
                  <li>MANiBOT will act as an assistant carrying out routine duties, enabling employees to engage more with clients and to take on more intellectually stimulating tasks, leading to improved customer service and job satisfaction. </li>
                  <li>MANiBOT will lead to better acceptance of robots by workers, companies and the general public. </li>   
                  <li>MANiBOT will revolutionise manipulation procedures in a wide range of sectors, (e.g., logistics, transport, agri-food, manufacturing), cementing European industry leadership across the digital supply chain. </li>
                </ul>
                <h5  style={{textAlign:'justify',color:'#2f5597',fontWeight:'600'}}>
                Why does the project focus on baggage handling and supermarket shelves’ restocking?
                </h5>
                <p style={{textAlign:'justify'}}>These two applications imply a wide diversity of movements and manipulations in different environments, with different types and weights of objects. They showcase how MANiBOT’s novel methods will be easily implemented in other settings. </p>
                <p style={{textAlign:'justify'}}>These settings entail a high level of manual labour, which often lacks attractiveness to potential employees, and there is a big potential for improvement of working conditions through MANiBOT. They are also significant sectors. Supermarkets are of everyday importance to the general public, and with the demand for air travel increasing, baggage handling capacity is increasingly critical. </p>

                <h5  style={{textAlign:'justify',color:'#2f5597',fontWeight:'600'}}>
                Which other applications could MANiBOT have? Which other sectors could it benefit?
                </h5>
                <p style={{textAlign:'justify'}}>MANiBOT could be relevant in many situations that involve procedures where diverse objects need to be handled and moved, such as logistics in various sectors, as well as manufacturing industries and agrifood. Automated material handling (AMH) systems supports efficiency in the manufacturing area, warehouse, retail shops, airports, distribution, and logistics centres.</p>

                <h5  style={{textAlign:'justify',color:'#2f5597',fontWeight:'600'}}>
                How will AI be used? How will MANiBOT ensure ethical and trustworthy AI and ensure ethics are upheld in all aspects of the project? How does MANiBOT ensure data privacy and security?
                </h5>
                <p style={{textAlign:'justify'}}>The MANiBOT project aims to set a standard for responsible development of robotic technologies, ensuring innovations are both technically feasible and ethically and legally sound.An Ethics Report has been produced by an external Ethics Advisor, outlining the main ethical and privacy risks and challenges and the measures to reduce or mitigate these risks. 
                </p>
                <p style={{textAlign:'justify'}}>AI will be integrated into MANiBOT’s systems from sensing to perception, control and cognition. MANiBOT's AI will use advanced techniques to help the robot learn how best to operate and to make decisions. MANiBOT will create a framework that  will include mechanisms for data privacy, security, and explainability to ensure that the AI operates in a manner that is fair, accountable, and aligned with ethical principles.</p>
                <p style={{textAlign:'justify'}}>Regular evaluations of algorithms and data sets will be carried out to identify and neutralize biases, promoting fairness and non-discrimination. .A Data Management and Ethics Plan explains details the practices used in the project for ethical data collection. MANiBOT observes the principles of data minimization and purpose limitation, ensures data integrity and GDPR-compliant data management.. The project will carry out regular risk assessments and refine mitigation actions if needed.</p>

                <h5  style={{textAlign:'justify',color:'#2f5597',fontWeight:'600'}}>
                What kind of testing will the MANiBOT solution undergo? How will you make sure the robots are safe?                </h5>
                <p style={{textAlign:'justify'}}>Ensuring safe, advanced human-aware robot navigation is a core element of the MANiBOT solution, addressing human safety and comfort. Cobot safety norms will be adhered to and the robot will be safety certified. 
                </p>
                <p style={{textAlign:'justify'}}>MANiBOT will incorporate risk assessment and mitigation strategies during the design phase. This will help identify and address potential hazards, ensuring that both the hardware and software components meet safety requirements.</p>
                <p style={{textAlign:'justify'}}>Extensive testing will be conducted through an iterative process involving multiple phases and pilot sites. These tests will simulate real-world scenarios to evaluate the performance and safety of the robots, helping to identify and mitigate any unforeseen issues.</p>
                <p style={{textAlign:'justify'}}>Initial tests will be conducted using standalone software and hardware components. The components will be tested on specially designed testbeds that closely simulate supermarket and airport environments. Once the individual components are ready and integrated, a prototype of the MANiBOT bi-manual robot will be released (by month 33). This prototype will be used to demonstrate the use cases and undergo evaluation.</p>
                <p style={{textAlign:'justify'}}>Final Testing at Pilot Sites: The final system prototype will be tested in realistic settings at designated pilot sites: Pilot Site 1 (Masoutis, Greece): Testing in a supermarket environment with single-item restocking. Pilot Site 2 (Schwarz Digital, Germany): Testing in a supermarket environment with box-based restocking. This site allows full control over the environment without customer traffic. Pilot Site 3 (Fraport Greece, Thessaloniki Airport): Testing in an airport environment focusing on baggage handling.</p>
                <p style={{textAlign:'justify'}}>A data management plan will oversee the processes related to ethical and safety approvals of the proposed applications at the pilot sites.The project will implement a strong security framework to protect the robot's operations. This includes ensuring secure communication protocols and protecting sensitive data.</p>
                <p style={{textAlign:'justify'}}>Mechanisms for regular software updates and maintenance checks will be included to ensure ongoing safety and functionality. This will help in addressing any vulnerabilities and improving performance over time.</p>
                <h5  style={{textAlign:'justify',color:'#2f5597',fontWeight:'600'}}>
                How does MANiBOT handle complex or unexpected tasks?
                </h5>
                <p style={{textAlign:'justify'}}>MANiBOT will use its advanced cognitive and physical capabilities to understand, move and handle different, sometimes unpredictable types of objects, even in tight or challenging spaces. It is designed to handle complex or unexpected tasks by gathering information, learning from its experiences and adapting its actions quickly. The robot will continuously improve and adapt its behaviour based on the situation.</p>
                <h5  style={{textAlign:'justify',color:'#2f5597',fontWeight:'600'}}>
                Who are the key partners and stakeholders in the MANiBOT project?
                </h5>
                <p style={{textAlign:'justify'}}>The main partners, who are carrying out the work with funding via the project, can be found <a href='https://manibot-project.eu/consortium#partners'>here</a>. Other stakeholders that are expected to benefit from MANiBOT are the following;
                </p>  
                <p style={{textAlign:'justify'}}><span style={{fontStyle:'italic'}}>Companies that involve procedures of diverse object handling;</span> the end users: Both at a management and an employee’s level, companies, service providers and industries that need diverse object handling could benefit from the proposed system in terms of efficiency, profit, healthier working conditions and more stimulating work duties alleviated from difficult tasks. They include supermarkets and retail shops, airports and baggage handling service providers, as well as logistics and manufacturing industries.   </p> 
                <p style={{textAlign:'justify'}}><span style={{fontStyle:'italic'}}>Researchers and robotics technology providers:</span>  Research and academic organisations, scientific journals, Committees, related EU-funded projects, industries and SMEs in robotics and other working groups in all research fields on which MANiBOT will focus can benefit from the research and outcomes of the project. The multidisciplinary nature of MANiBOT will be relevant for distinct scientific communities.</p> 
                <p style={{textAlign:'justify'}}><span style={{fontStyle:'italic'}}>Public Bodies, Policy makers, Related Organizations:</span>  This includes public bodies at national and European level (industrial committees, ministries and regional governments), who would have an interest in the positive impacts MANiBOT can have on the other stakeholders. Standardization bodies and as well as organizations and associations promoting robotics research and excellence will also have an interest in the MANiBOT outcomes.</p>
                <p style={{textAlign:'justify'}}><span style={{fontStyle:'italic'}}>General Public:</span>  MANiBOT aims to increase their understanding and acceptance of robots, through highlighting their proven safety, trustworthiness and dependability. </p>
                <h5  style={{textAlign:'justify',color:'#2f5597',fontWeight:'600'}}>
                How will MANiBOT be integrated into existing systems & workflows?
                </h5>
                <p style={{textAlign:'justify'}}>In supermarkets:</p>
                <p style={{textAlign:'justify'}}>MANiBOT is designed to seamlessly integrate into existing supermarket systems and workflows, focusing on the shelf replenishment process. The robot will restock shelves, autonomously navigate aisles, and follow established best practices for product placement. It will operate alongside employees responsible for the replenishment of shelves to support their efforts.</p>
                <p style={{textAlign:'justify'}}>Initially, training will be provided to personnel responsible for the replenishment of shelves to ensure effective interaction with the robot. A user-friendly graphical user interface (GUI) will enable human supervisors to provide the robot assignments, schedule them, and monitor the robot’s activities in real time. Supervisors will track progress, make adjustments, and intervene if necessary, ensuring smooth and adaptable integration.</p>
                <p style={{textAlign:'justify'}}>In conclusion, the combination of a user-friendly GUI and comprehensive staff training will be crucial to robot’s smooth integration into supermarket operations. These elements will ensure that MANiBOT enhances existing workflows and boosts overall efficiency with minimal disruption.</p>
                <p style={{textAlign:'justify'}}>In baggage handling:</p>

                <p style={{textAlign:'justify'}}>MANiBOT will be integrated into the existing airport baggage handling system to enhance the efficiency of baggage loading and unloading processes. Positioned along the baggage conveyor belt and equipped with advanced sensors and AI capabilities, the robot will autonomously identify, handle, and transfer luggage between conveyor belts and carts, and vice versa. By connecting to the airport’s Baggage Tracking and Reconciliation System (BTRS), MANiBOT will verify key details, such as flight information, weight, and baggage tag details, ensuring only luggage from the allocated flight is processed. Human operators will supervise MANiBOT’s activities through a graphical user interface (GUI), allowing them to monitor the robot’s movements, assign tasks to the robot, and intervene when necessary. This integration will transform airport operations by introducing human-like manipulation to repetitive and labour intensive tasks and driving innovation in the industry.</p>                <h5  style={{textAlign:'justify',color:'#2f5597',fontWeight:'600'}}>
                How can I stay informed about MANiBOT's progress?
                </h5>
                <p style={{textAlign:'justify'}}>You can subscribe to our newsletter <a href='https://manibot-project.us22.list-manage.com/subscribe?u=3fc0b5ad1e28dfc0eee470610&id=5654c5fdc6'>here</a>, and follow us on <a href='https://www.linkedin.com/showcase/manibot/'>LinkedIn</a>, <a href='https://twitter.com/MANiBOT_project'>X</a>, and <a href='https://twitter.com/MANiBOT_project'>YouTube</a>. You will also find news articles in the <a href='https://manibot-project.eu/news#news'>News</a> section of our website.</p>
                <h5  style={{textAlign:'justify',color:'#2f5597',fontWeight:'600'}}>
                How is MANiBOT funded?
                </h5>
                <p style={{textAlign:'justify'}}>MANiBOT receives funding from the EU’s Horizon Europe programme with an amount of €6,285,273.75. The specific programme is the strand Artificial Intelligence and Robotics, under the Digital, Industry and Space programme of Horizon Europe. The University of Bristol is an Associated Partner and receives funding of £604,766 from the Horizon Europe Guarantee of UK Research and Innovation.</p>
                <h5  style={{textAlign:'justify',color:'#2f5597',fontWeight:'600'}}>
                What is the timeline for the MANiBOT project?
                </h5>
                <p style={{textAlign:'justify'}}>The MANIBOT project started at the beginning of November 2023 and will finish at the end of April 2027. During this time, various deliverables and publications will be produced, and the public ones will be found on the website <a href='https://manibot-project.eu/outcomes#publications'>here</a>.</p>
              </Col>
            </Row>
            </Container>
            </section>
           
       
        <DarkFooter />
       
      </div>
    </>
  );
}

export default FAQ;
