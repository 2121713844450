import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import '../assets/scss/newstyle.scss'
import imageblog1 from '../assets/img/Use Cases graphic.png'
import imageblog2 from '../assets/img/presentation.png'
import MyPDF23 from './presentation.pdf'

const BlogList = () => {




  return (
    <div id="layout" className="pure-g">
      <div className="content pure-u-1 pure-u-md-3-4">
        <div>
          <div className="posts" >

		  <section >
                	<div class="container py-2">
		<div class="h1 text-center text-dark" id="pageHeaderTitle"></div>
		<article class="postcard light blue">
			<a class="postcard__img_link" >
				<img class="postcard__img"  title="" src={imageblog1} />
			</a>
			<div class="postcard__text t-dark">
				<h1 class="postcard__title blue"><a>Copyright-Free Image of Use Cases </a></h1>
				<div class="postcard__subtitle small">
					<time >
						<i class="fas fa-calendar-alt mr-2"></i>
						10/09/2024
					</time>
				</div>
				<div class="postcard__bar"></div>
				<div class="postcard__preview-txt">Download our image showing the two use cases of the project. </div>
				<ul class="postcard__tagbox">
					
				<button id='button2' >
   <a style={{width:'130px',fontSize:'14px'}}  href={imageblog1} download="Use Cases graphic.png">Download here</a>

                  </button>
				</ul>
			</div>
		</article>
		<article class="postcard light blue">
			<a class="postcard__img_link" >
				<img class="postcard__img"  title="" src={imageblog2} />
			</a>
			<div class="postcard__text t-dark">
				<h1 class="postcard__title blue"><a>One-page overview of the project. </a></h1>
				<div class="postcard__subtitle small">
					<time >
						<i class="fas fa-calendar-alt mr-2"></i>
						10/09/2024
					</time>
				</div>
				<div class="postcard__bar"></div>
				<div class="postcard__preview-txt">Read a one-page summary of our project here.</div>
				<ul class="postcard__tagbox">
					
          <button id='button2' >
   <a style={{width:'130px',fontSize:'14px'}}  href={MyPDF23} download="MANiBOT one page presentation.pdf">Download here</a>

                  </button>
				</ul>
			</div>
		</article>
		<article class="postcard light blue">

			<div class="postcard__text t-dark">
			<p style={{fontSize:'12px',fontWeight:'600',color:'#5072a4',textAlign:'left'}}>PRESS RELEASE</p>
				<h1 class="postcard__title blue">Newly launched pioneering project promises a step change in bi-manual mobile robots</h1>
				<div class="postcard__subtitle small">
					<time >
						<i class="fas fa-calendar-alt mr-2"></i>
						27/12/2023
					</time>
				</div>
				<div class="postcard__bar"></div>
				<div class="postcard__preview-txt">The EU-funded MANiBOT project, aiming to empower service robots with superior physical capabilities, was launched  at the Centre for Research and Technology Hellas (CERTH) in Thessaloniki, Greece, in late 2023.</div>
				<ul class="postcard__tagbox">
					
          <Link
                   to ='/Newly launched pioneering project promises a step change in bi-manual mobile robots'
                    className="buttonaki">
                    Read More
                  </Link>
				</ul>
			</div>
		</article>
    </div>
              
              </section>

         
          </div>


          
        </div>
      </div>
    </div>
  )
}

export default BlogList