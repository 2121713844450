import React, { useEffect, useState } from 'react'
import { Link, useParams } from "react-router-dom";

import ExamplesNavbar from './Navbars/ExamplesNavbar';
import DarkFooter from './Footers/DarkFooter';
import {Helmet} from "react-helmet";
import image1 from '../assets/img/image111.jpg'
import image2 from '../components/Navbars/manibot-final.png'
import image3 from '../assets/img/image333.jpg'

const Article7 = () => {



  return (
    <>   
    <div id="layout" className="pure-g">
       <ExamplesNavbar />
      
      <div className="content pure-u-1 pure-u-md-3-4">
        <div>
          <div className="posts">
            

          <section className="post" style={{display:'grid'}}>
                <div className='singleblog'>
                <header className="post-header">
                  <h3 style={{color:'#07353d'}} className="post-title pt-3">
                  TU WIEN develops ZS6D: Zero-Shot 6D Object Pose Estimation    
                  </h3>
                  <div class="postcard__subtitle">
					<time >
						<i class="fas fa-calendar-alt mr-2"></i>
            24/09/2024
					</time>
				</div>
        <br/>
                 
                </header>
                <div className="post-description">
              
                <p style={{textAlign:'justify'}}>
               
                As robotic systems interact with increasingly complex environments, recognizing a wide variety
of objects becomes crucial. Traditional 6D object pose estimation methods rely on object-
specific training, which limits their ability to generalise to unseen objects. Recent methods
address this limitation by employing deep template matching through fine-tuned CNNs.
However, these approaches require costly data rendering and extensive training.
</p>
<p style={{textAlign:'justify'}}>
To overcome these challenges, TU WIEN introduced ZS6D, a zero-shot 6D object pose
estimation method. ZS6D uses visual descriptors from pre-trained Vision Transformers (ViT) to
match rendered templates with query images and establish local correspondences. These
correspondences are used to estimate an object's 6D pose using RANSAC-based PnP, without
requiring task-specific fine-tuning.</p>
<p style={{textAlign:'justify'}}>Their experiments demonstrate that ZS6D is an improvement on state-of-the-art methods like
MegaPose and OSOP on the LMO, YCBV, and TLESS datasets. The use of ViT descriptors
leads to superior performance in generalisation without the need for massive datasets and
extensive training.</p>
<p style={{textAlign:'justify'}}>This novel approach will be integrated into the MANiBOT cobot, which will demonstrate fast and
effective manipulation, even of unknown objects, in environments with a human presence. </p>
<p style={{textAlign:'justify'}}>You can read the publication <a href='https://www.google.com/url?sa=D&q=https://ieeexplore.ieee.org/document/10611464&ust=1727784900000000&usg=AOvVaw3VFWWZzEMaDC4xS6S2EQNT&hl=en&source=gmail'>here</a>. </p> 
</div>
                
                
                </div>
               
                
              </section>
               
                    
          </div>


          
        </div>
      </div>
      <DarkFooter/>
    </div>
    </>
  )
}

export default Article7