import React, { useEffect, useState } from 'react'
import { Link, useParams } from "react-router-dom";

import ExamplesNavbar from './Navbars/ExamplesNavbar';
import DarkFooter from './Footers/DarkFooter';
import {Helmet} from "react-helmet";
import image1 from '../assets/img/image111.jpg'
import image2 from '../assets/img/photo1.jpg'
import image3 from '../assets/img/photo2.jpg'

const Article8 = () => {



  return (
    <>   
    <div id="layout" className="pure-g">
       <ExamplesNavbar />
      
      <div className="content pure-u-1 pure-u-md-3-4">
        <div>
          <div className="posts">
            

          <section className="post" style={{display:'grid'}}>
                <div className='singleblog'>
                <header className="post-header">
                  <h3 style={{color:'#07353d'}} className="post-title pt-3">
                  Third MANiBOT plenary meeting gathers partners to review advances    
                  </h3>
                  <div class="postcard__subtitle">
					<time >
						<i class="fas fa-calendar-alt mr-2"></i>
            10/10/2024
					</time>
				</div>
        <br/>
                 
                </header>
                <div className="post-description">
                <img style={{float:'left',margin:' 0px  10px 10px 0px'}} src={image2} title=""  height="240" />
                <img style={{float:'left',margin:' 0px  10px 10px 0px'}} src={image3} title=""  height="240" />
                <p style={{textAlign:'justify'}}>
               
From the 1st-2nd October MANiBOT project partners convened in Germany for a productive plenary meeting, hosted by 
<a href='https://schwarz-digits.de/'> SDI</a>. The event covered extensive ground across all work packages, showcasing progress and addressing key developments and future actions on the journey towards the MANiBOT solution.
</p>
<p style={{textAlign:'justify'}}>
In depth presentations on work packages related to requirements, technical specifications, manipulation primitives, and robot cognition were reviewed on day one. Partners shared updates on various tasks, from safe navigation and bimanual motion coordination to context-aware planning and human-robot interaction. The first day also featured a visit to the test supermarket, where partners were guided through the aisles, experiencing demonstrations of the technologies that are being tested by SDI.</p>
<p style={{textAlign:'justify'}}>The second day focused on adaptive robot perception, bimanual dexterous robot platforms and system integration. Presentations highlighted advancements in object detection, pose estimation and multimodal sensing. Progress on tactile and proximity sensors and mobile manipulator mechatronics was reviewed. </p>
<p style={{textAlign:'justify'}}>This novel approach will be integrated into the MANiBOT cobot, which will demonstrate fast and

Other topics of discussion included the forces required for the cobot’s handling tasks, the methodologies for simulating the airport use case and the lab setups for each use case. The meeting concluded with an overview of dissemination and exploitation activities, including updates on the project's online presence and communication efforts.
</p>
<p style={{textAlign:'justify'}}>Throughout the meeting, partners engaged in fruitful discussions, addressing technical questions and aligning their efforts across work packages. The consortium  (<a href="https://www.auth.gr/en/">Aristotle University of Thessaloniki</a>, 
 <a href="https://m.ciop.pl/en"> Central Institute for Labour Protection - National Research Institute</a>, 
 <a href="https://www.certh.gr/root.en.aspx"> Centre for Research and Technology Hellas</a>, 
 <a href="https://www.masoutis.gr/"> Diamantis Masoutis Supermarkets</a>, 
 <a href="https://www.fraport-greece.com/eng/"> Fraport Regional Airports of Greece Management</a>, 
 <a href="https://www.santannapisa.it/en"> Sant'Anna School of Advanced Studies</a>, 
 <a href="https://digital.schwarz/en"> Schwarz Digital GmbH & Co. KG</a>,
 <a href="https://digital.schwarz/en"> Asea Brown Boveri SA</a>,
 <a href="https://www.tu-darmstadt.de/index.en.jsp"> Technical University of Darmstadt</a>, 
 <a href="https://www.tuwien.at/en/"> Technical University of Vienna</a>, 
 <a href="https://www.twi-hellas.com/"> TWI Hellas</a>, 
 <a href="https://www.bristol.ac.uk/"> University of Bristol</a>, 
 <a href="https://www.ubu.es/english-version"> University of Burgos</a>), is looking forward to the continued collaboration and the upcoming review, where developments and achievements will be showcased.</p> 
</div>
                
                
                </div>
               
                
              </section>
               
                    
          </div>


          
        </div>
      </div>
      <DarkFooter/>
    </div>
    </>
  )
}

export default Article8