/*

=========================================================
* Now UI Kit React - v1.5.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-kit-react
* Copyright 2021 Creative Tim (http://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/now-ui-kit-react/blob/main/LICENSE.md)

* Designed by www.invisionapp.com Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import {Helmet} from "react-helmet";
import favicon from './components/Navbars/favicon.svg'

// styles for this kit
import "./assets/css/bootstrap.min.css";
import "./assets/scss/now-ui-kit.scss";
import "./assets/demo/demo.css?v=1.5.0";
import "./assets/demo/nucleo-icons-page-styles.css?v=1.5.0";
// pages for this kit

import LandingPage from "./views/pages/LandingPage.js";
import Project from "./views/pages/Project.js"; 
import PrivacyPolicy from "./views/pages/PrivacyPolicy";
import ProjectPage from "./views/pages/Project.js";
import Partners from "./views/pages/Partners.js";
import Outcomes from "./views/pages/Outcomes";
import Connect from "./views/pages/Connect";
import News from "./views/pages/News";
import { hotjar } from 'react-hotjar';
import PiwikPro from '@piwikpro/react-piwik-pro';
import Article1 from "./components/Article1";
import Article2 from "./components/Article2";
import Article3 from "./components/Article3";
import Article4 from "./components/Article4";
import Article5 from "./components/Article5";
import Article6 from "./components/Article6";
import Article7 from "./components/Article7";
import Article8 from "./components/Article8";
import FAQ from "./views/pages/FAQ";
import Blog1 from "./components/Blog1";


PiwikPro.initialize('b064fc35-69d8-4240-96b3-cc5c12e55813', 'https://pantheon-project.piwik.pro');



ReactDOM.render(
  <BrowserRouter>
  <Helmet>
        <meta charSet="utf-8"/>
        <title>MAΝiBOT</title>
        <link rel="image_src" href="/static/media/tubers_logo2.svg" />
        <link rel="icon" type="image/png" href={favicon} sizes="16x16" />
        <meta name="theme-color" content="#008f68" />

  </Helmet>
    <Switch>
      <Switch>
        <Route exact path="/" render={(props) => <LandingPage {...props} />} />
        <Route path="/project" component={ProjectPage} />
        <Route path="/consortium" component={Partners} />
        <Route path="/outcomes" component={Outcomes} />
        <Route path="/news" component={News} />
        <Route path="/connect" component={Connect} />
        <Route path="/FAQ" component={FAQ} />
        <Route path="/Newly launched pioneering project promises a step change in bi-manual mobile robots" component={Article1} />
        <Route path="/MANiBOT project commences journey to revolutionise mobile robots" component={Article2} />
        <Route path="/MANiBOT debuts at online showcase event" component={Article3} />
        <Route path="/MANiBOT Consortium gathers in Italy to discuss latest developments" component={Article4} />
        <Route path="/MANiBOT Joins the First Automation and Robotics Expo in Athens" component={Article5} />
        <Route path="/MANiBOT research presented at ICRA 2024" component={Article6} />
        <Route path='/TU WIEN develops ZS6D: Zero-Shot 6D Object Pose Estimation' component={Article7} />
        <Route path='/Third MANiBOT plenary meeting gathers partners to review advances' component={Article8} />
        <Route path='/The MANiBOT Project: its Goal and Importance' component={Blog1} />
      </Switch>
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
